<template>
  <div class="index">
    <div ref="header">
      <BlackHeader :bg="bg"></BlackHeader>
    </div>
    <div class="toubu">
      <div class="text">
        服务全国90%地区
        <div class="bottom">
          营销中心服务全国 为客户提供稳定智能可靠的技术服务
        </div>
      </div>
    </div>
    <!-- <div class="nav">
      <div
        class="son"
        v-for="(item, index) in list"
        :key="index"
        @click="active(item)"
      >
        <img :src="item.src" alt class="img" />
        <div class="text">
          <div class="top" :class="type == item.type ? 'active' : ''">
            {{ item.top }}
          </div>
          <div class="bottom">{{ item.bottom }}</div>
        </div>
      </div>
      <em></em>
    </div> -->
    <!-- <Chengdu v-if="type == 0"></Chengdu>
    <Beijing v-if="type == 1"></Beijing>
    <Jiangshu v-if="type == 2"></Jiangshu>
    <Shenzhen v-if="type == 3"></Shenzhen>
    <Wuhan v-if="type == 4"></Wuhan> -->

    <div class="newbox">
      <div class="newbox-list">
        <!-- <div class="title">全国服务区域</div> -->
        <div class="title">全国服务区域</div>
        <div class="more">
          汉康技术服务团队，服务全国多个地区，提供专业的技术开发、设计、实施、售后服务内容
        </div>
        <div class="citybox">
          <div
            class="son"
            v-for="(item, index) in city"
            :key="index"
            :class="xuanzhong == item.type ? 'active' : ''"
            @click="xuanzhong = item.type"
          >
            <div class="em"></div>

            <div class="diqu">{{ item.diqu }}</div>
            <div class="biaoqian">
              <div class="icon" v-for="(v, i) in item.more" :key="i">
                {{ v.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="newmap">
        <div class="san"></div>
        <div class="yi"></div>
        <div class="er"></div>

        <div class="xibu" :class="xuanzhong == 0 ? 'op1' : ''"></div>
        <div class="huabei" :class="xuanzhong == 1 ? 'op1' : ''"></div>
        <div class="huadong" :class="xuanzhong == 2 ? 'op1' : ''"></div>
        <div class="huanan" :class="xuanzhong == 3 ? 'op1' : ''"></div>
        <div class="huazhong" :class="xuanzhong == 4 ? 'op1' : ''"></div>

        <!-- <div class="sc" :class="xuanzhong == 0 ? 'op1' : ''"></div>
        <div class="bj" :class="xuanzhong == 1 ? 'op1' : ''"></div>
        <div class="sh" :class="xuanzhong == 2 ? 'op1' : ''"></div>
        <div class="gd" :class="xuanzhong == 3 ? 'op1' : ''"></div>
        <div class="hb" :class="xuanzhong == 4 ? 'op1' : ''"></div> -->

        <div class="sc"></div>
        <div class="bj"></div>
        <div class="sh"></div>
        <div class="gd"></div>
        <div class="hb"></div>

        <div
          class="dian"
          v-for="(item, index) in dian"
          :key="index"
          :style="item.style"
          :class="xuanzhong == item.type ? 'dian-active' : ''"
        >
          <img
            class="dingwei"
            :src="xuanzhong == item.type ? item.active : item.src"
            alt=""
          />

          {{ item.name }}
        </div>
      </div>
      <!-- <div class="map">
        <div
          class="son"
          v-for="(item, index) in dian"
                    :key="index"
                    :style="item.style"
        >
          {{ item.name }}
          <img :src="type == item.type ? item.active : item.src" alt="" />
        </div>
      </div> -->
      <!-- <div class="right">
        <div class="name">全国分布区域</div>
        <div class="nav">
          <div
            class="son"
            v-for="(item, index) in newlist"
            :key="index"
            @click="activemap(item)"
            :class="item.type == type ? 'active' : ''"
          >
            {{ item.name }}
            <em v-show="item.type == type"></em>
          </div>
        </div>
        <div
          class="mian"
          v-for="v in newlist"
          :key="v.name"
          v-show="v.type == type"
        >
          <div class="son">{{ v.dizhi }}</div>
          <div class="son">{{ v.phone }}</div>
          <div class="son">{{ v.youxiang }}</div>

          <div id="container" v-if="v.type == type"></div>
        </div>
      </div> -->
    </div>

    <div class="bottom-num">
      <div class="son" v-for="(item, index) in numlist" :key="index">
        <div class="name">{{ item.name }}</div>
        <div class="num" v-if="show">
          <ICountUp :endVal="item.num" :options="options" /><span class="danwei"
            >{{ item.danwei }}
          </span>
        </div>
      </div>
    </div>

    <Footer></Footer>

    <div class="toback" ref="toback">
      <div class="toback-lianxi">
        <div class="toback-ph">
          <div class="toback-sons">
            <div class="toback-barss">
              <div class="toback-top">咨询电话</div>
              <div class="toback-bottom">133-3098-1239</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">技术支持</div>
              <div class="toback-bottom">189-8005-3442</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">售后电话</div>
              <div class="toback-bottom">400-8878-352</div>
            </div>
          </div>
        </div>
        <div class="toback-wx">
          <div class="toback-son">
            <img src="../../assets/imgs/home/gongzonghao.png" alt />
            <div class="toback-tip">(成都汉康微信)</div>
          </div>
        </div>
      </div>
      <div class="toback-up" @click="up"></div>
    </div>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
//import AMapLoader from "@amap/amap-jsapi-loader";
import BlackHeader from "../../components/BlackHeader.vue";
import Footer from "../../components/Footer.vue";
// import Chengdu from "./components/chengdu";
// import Beijing from "./components/beijing";
// import Jiangshu from "./components/jiangshu";
// import Shenzhen from "./components/shenzhen";
// import Wuhan from "./components/wuhan";
export default {
  components: {
    BlackHeader,
    Footer,
    // Chengdu,
    // Beijing,
    // Jiangshu,
    // Shenzhen,
    // Wuhan,
    ICountUp,
  },
  data() {
    return {
      type: 1,
      // list: [
      //   {
      //     src: require("../../assets/imgs/yingxiao/da.png"),
      //     top: "西部/WEST",
      //     bottom: "成都",
      //     type: 0,
      //   },
      //   {
      //     src: require("../../assets/imgs/yingxiao/da.png"),
      //     top: "华北/NORTH CHINA",
      //     bottom: "北京",
      //     type: 1,
      //   },

      //   {
      //     src: require("../../assets/imgs/yingxiao/da.png"),
      //     top: "华东/EAST CHINA",
      //     bottom: "江苏",
      //     type: 2,
      //   },
      //   {
      //     src: require("../../assets/imgs/yingxiao/da.png"),
      //     top: "华南/SOUTH CHINA",
      //     bottom: "深圳",
      //     type: 3,
      //   },
      //   {
      //     src: require("../../assets/imgs/yingxiao/da.png"),
      //     top: "华中/CENTRAL CHINA",
      //     bottom: "武汉",
      //     type: 4,
      //   },
      // ],
      dian: [
        {
          name: "成都",
          style: "left:17vw;bottom:21vw",
          src: require("../../assets/imgs/guanyu/dingwei.png"),
          active: require("../../assets/imgs/guanyu/dingwei-2.png"),
          type: 0,
        },
        {
          name: "北京",
          style: "left:26vw;bottom:28.5vw",
          src: require("../../assets/imgs/guanyu/dingwei.png"),
          active: require("../../assets/imgs/guanyu/dingwei-2.png"),
          type: 1,
        },

        {
          name: "上海",
          style: "left:30vw;bottom:20vw",
          src: require("../../assets/imgs/guanyu/dingwei.png"),
          active: require("../../assets/imgs/guanyu/dingwei-2.png"),
          type: 2,
        },
        {
          name: "深圳",
          style: "left:25vw;bottom:14.5vw",
          src: require("../../assets/imgs/guanyu/dingwei.png"),
          active: require("../../assets/imgs/guanyu/dingwei-2.png"),
          type: 3,
        },
        {
          name: "武汉",
          style: "left:25vw;bottom:21vw",
          src: require("../../assets/imgs/guanyu/dingwei.png"),
          active: require("../../assets/imgs/guanyu/dingwei-2.png"),
          type: 4,
        },
      ],
      newlist: [
        {
          name: "成都",
          dizhi: "地址：成都市武侯区二环路南三段如意人家商务楼三楼",
          phone: "电话：028-86660094",
          youxiang: "邮箱：2853750085@qq.com",
          lng: "104.029938",
          lat: "30.608791",
          type: 1,
          id: "chengdu",
        },
        {
          name: "武汉",
          dizhi: "地址：成都市武侯区二环路南三段如意人家商务楼三楼",
          phone: "电话：028-86660094",
          youxiang: "邮箱：2853750085@qq.com",
          lng: "114.304569",
          lat: "30.593354",
          type: 2,
          id: "wuhan",
        },
        {
          name: "南京",
          dizhi: "地址：成都市武侯区二环路南三段如意人家商务楼三楼",
          phone: "电话：028-86660094",
          youxiang: "邮箱：2853750085@qq.com",
          lng: "118.796624",
          lat: "32.059344",
          type: 3,
          id: "nanjing",
        },
        {
          name: "北京",
          dizhi: "地址：成都市武侯区二环路南三段如意人家商务楼三楼",
          phone: "电话：028-86660094",
          youxiang: "邮箱：2853750085@qq.com",
          lng: "116.378517",
          lat: "39.865246",
          type: 4,
          id: "wuhan",
        },
        {
          name: "深圳",
          dizhi: "地址：成都市武侯区二环路南三段如意人家商务楼三楼",
          phone: "电话：028-86660094",
          youxiang: "邮箱：2853750085@qq.com",
          lng: "114.057939",
          lat: "22.543527",
          type: 5,
          id: "shenzhen",
        },
      ],

      numlist: [
        { name: "全国案例分布区域", num: 90, danwei: "%" },
        { name: "总服务客户数量", num: 358, danwei: "万" },
        { name: "公司分布区域", num: 60, danwei: "%" },
        { name: "技术合作伙伴", num: 2000, danwei: "+" },
      ],
      options: {
        useEasing: true,
        useGrouping: true,
        separator: "", //分割 符号
        decimal: ".",
        prefix: "",
        suffix: "",
        duration: 2.5,
      },
      arr: [],
      show: false,
      timer: null,

      xuanzhong: 0,
      city: [
        {
          diqu: "西部地区丨成都",
          more: [
            { name: "四川" },
            { name: "重庆" },
            { name: "贵州" },
            { name: "云南" },
            { name: "西藏" },
            { name: "陕西" },
            { name: "甘肃" },
            { name: "新疆" },
            { name: "青海" },
            { name: "宁夏" },
          ],
          type: 0,
        },
        {
          diqu: "华北地区丨北京",
          more: [
            { name: "北京" },
            { name: "天津" },
            { name: "河北" },
            { name: "山西" },
            { name: "内蒙古" },
            { name: "辽宁" },
            { name: "吉林" },
            { name: "黑龙江" },
          ],
          type: 1,
        },
        {
          diqu: "华东地区丨上海",
          more: [
            { name: "上海" },
            { name: "江苏" },
            { name: "浙江" },
            { name: "山东" },
            { name: "安徽" },
          ],
          type: 2,
        },
        {
          diqu: "华南地区丨深圳",
          more: [
            { name: "广东" },
            { name: "广西" },
            { name: "海南" },
            { name: "福建" },
            { name: "台湾" },
          ],
          type: 3,
        },
        {
          diqu: "华中地区丨武汉",
          more: [
            { name: "湖北" },
            { name: "湖南" },
            { name: "河南" },
            { name: "江西" },
          ],
          type: 4,
        },
      ],
      bg: null,
    };
  },

  methods: {
    // active(val) {
    //   this.type = val.type;
    // },
    up() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      let sudu = 40;
      if (top >= 0 && top < 1000) {
        sudu = 40;
      } else if (top >= 1000 && top < 2000) {
        sudu = 60;
      } else if (top >= 2000 && top < 3000) {
        sudu = 100;
      } else if (top >= 3000 && top < 4000) {
        sudu = 140;
      } else {
        sudu = 200;
      }
      this.timer = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            sudu;

        if (top <= 0) {
          clearInterval(this.timer);
        }
      }, 10);

      // document.body.scrollTop = 0;
    },

    activemap(item) {
      this.type = item.type;
      let arr = [Number(item.lng), Number(item.lat)];

      this.initMap(arr);
    },
    // initMap(arr) {
    //   AMapLoader.load({
    //     key: "3e8a58c5994f7bc3809ace120bfade85", // 申请好的Web端开发者Key，首次调用 load 时必填
    //     version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    //     plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    //   })
    //     .then((AMap) => {
    //       // var marker = new AMap.Marker({
    //       //   position: new AMap.LngLat(104.029938, 30.608791), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
    //       //   title: "公司",
    //       // });
    //       // this.map.add(marker);
    //       this.map = new AMap.Map("container", {
    //         //设置地图容器id
    //         viewMode: "3D", //是否为3D地图模式
    //         zoom: 17, //初始化地图级别
    //         center: arr, //初始化地图中心点位置
    //       });
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },

    scroll() {
      let scroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.arr.push(scroll);
      //console.log("scroll :>> ", scroll);
      if (this.arr.length > 2) {
        let a = [this.arr[this.arr.length - 1]];
        let b = [this.arr[this.arr.length - 2]];

        if (a - b >= 0) {
          // console.log("往下滑");
          this.$refs.header.style.position = "absolute";
          this.$refs.header.style.top = "0";
          this.$refs.header.style.left = "0";
          this.$refs.header.style.zIndex = "100";

          if (scroll >= 470) {
            this.show = true;
          } else {
            this.show = false;
          }
        } else {
          // console.log("往上滑");
          if (scroll > 300) {
            this.$refs.header.style.position = "fixed";
            this.$refs.header.style.top = "0";
            this.$refs.header.style.left = "0";
            this.$refs.header.style.zIndex = "100";
            this.bg = "#fff";
          }
        }
        if (scroll > 120) {
          this.$refs.toback.style.opacity = "1";
        } else {
          this.$refs.toback.style.opacity = "0";
        }
      }
      if (scroll <= 280) {
        this.$refs.header.style.position = "absolute";
        this.$refs.header.style.top = "0";
        this.$refs.header.style.left = "0";
        this.$refs.header.style.zIndex = "100";
      }
    },
  },
  mounted() {
    document.body.scrollTop = 0;

    //DOM初始化完成进行地图初始化
    // let arr = [104.029938, 30.608791];
    // this.initMap(arr);
    window.addEventListener("scroll", this.scroll, true);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll, true);
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: rgba(48, 103, 255, 1) !important;
}

#container {
  padding: 0px;
  margin: 0px;
  width: 622px;
  height: 290px;
  margin-top: 50px;
}

.index {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .toubu {
    width: 1920px;
    height: 500px;
    background-image: url("../../assets/imgs/yingxiao/bg.png");
    background-size: 100% 100%;
    margin: 0 auto;
    position: relative;

    .text {
      // min-width: 462px;
      position: absolute;
      top: 180px;
      left: 343px;
      font-size: 60px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 84px;
      color: #333333;

      // border-bottom: 1px solid rgba(75, 75, 75, 1);
      // padding-bottom: 20px;
      .bottom {
        font-size: 20px;
        font-family: Alibaba PuHuiTi Xi;
        font-weight: normal;
        line-height: 22px;
        color: rgba(51, 51, 51, 0.7);
        margin-top: 8px;
      }
    }
  }

  // .nav {
  //   width: 1920px;
  //   margin: 0 auto;
  //   padding: 50px 260px;
  //   display: flex;
  //   flex-wrap: wrap;
  //   em {
  //     width: 1400px;
  //     height: 2px;
  //     background-color: rgba(235, 235, 235, 1);
  //   }
  //   .son {
  //     width: 400px;
  //     height: 70px;
  //     margin-right: 50px;
  //     margin-bottom: 50px;

  //     color: rgba(51, 51, 51, 1);
  //     display: flex;
  //     cursor: pointer;
  //     .img {
  //       width: 155px;
  //       height: 70px;
  //     }
  //     .text {
  //       margin-left: 30px;
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: center;
  //       .top {
  //         font-size: 20px;
  //       }
  //       .bottom {
  //         font-size: 14px;
  //       }
  //     }
  //   }
  //   .son:hover .top {
  //     color: rgba(48, 103, 255, 1);
  //   }
  // }

  .newbox {
    width: 100%;
    height: 900px;
    display: flex;
    justify-content: space-between;

    background: url("../../assets/imgs/yingxiao/center.png");
    background-size: 100% 100%;
    padding-left: 260px;
    padding-right: 260px;
    padding-top: 60px;

    .newbox-list {
      width: 556px;
      height: 200px;

      .title {
        font-size: 24px;
        font-family: Alibaba PuHuiTi !important;
        font-weight: normal;
        line-height: 33px;
        color: #333333;
      }

      .more {
        font-size: 18px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: normal;
        line-height: 20px;
        color: #333333;
        margin-top: 18px;
      }

      .citybox {
        width: 100%;
        height: 200px;
        margin-top: 40px;

        .son {
          width: 100%;
          height: 120px;
          padding: 12px 18px;
          cursor: pointer;
          position: relative;

          .em {
            width: 2px;
            height: 100%;
            background-color: rgba(68, 112, 255, 1);
            position: absolute;
            top: 0;
            left: -2px;
            opacity: 0;
          }

          .diqu {
            font-size: 18px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;
            line-height: 25px;
            color: #333333;
          }

          .biaoqian {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .icon {
              width: 64px;
              height: 26px;
              background: rgba(68, 112, 255, 0.1);
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              font-family: Alibaba PuHuiTi Xi !important;
              font-weight: normal;

              color: #777777;

              margin-top: 10px;
              margin-right: 6px;
            }
          }
        }

        .active {
          background: linear-gradient(
            270deg,
            rgba(68, 112, 255, 0) 0%,
            rgba(68, 112, 255, 0.1) 100%
          );

          .em {
            opacity: 1;
          }
        }
      }
    }

    .newmap {
      width: 760px;
      height: 760px;
      background: url("../../assets/imgs/yingxiao/4.png");
      background-size: 100% 100%;
      position: relative;

      .yi {
        width: 100%;
        height: 100%;
        background: url("../../assets/imgs/yingxiao/3.png");
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .san {
        width: 100%;
        height: 100%;
        background: url("../../assets/imgs/yingxiao/2.png");
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .er {
        width: 100%;
        height: 100%;
        background: url("../../assets/imgs/yingxiao/1.png");
        background-size: 100% 100%;
        position: absolute;
        top: -124px;
        left: 0;
        z-index: 10;
      }

      .xibu {
        width: 465.05px;
        height: 431.53px;
        background: url("../../assets/imgs/yingxiao/5.png");
        background-size: 100% 100%;
        position: absolute;
        top: 96px;
        left: 0;
        opacity: 0;
        transition: all 0.4s linear;
      }

      .huabei {
        width: 466.13px;
        height: 328.78px;
        background: url("../../assets/imgs/yingxiao/6.png");
        background-size: 100% 100%;
        position: absolute;
        top: 10px;
        right: 2px;
        opacity: 0;
        transition: all 0.4s linear;
      }

      .huanan {
        width: 246.59px;
        height: 315.8px;
        background: url("../../assets/imgs/yingxiao/7.png");
        background-size: 100% 100%;
        position: absolute;
        bottom: 14px;
        right: 130px;
        opacity: 0;
        transition: all 0.4s linear;
      }

      .huadong {
        width: 99.5px;
        height: 164.39px;
        background: url("../../assets/imgs/yingxiao/8.png");
        background-size: 100% 100%;
        position: absolute;
        bottom: 314px;
        right: 152px;
        opacity: 0;
        transition: all 0.4s linear;
      }

      .huazhong {
        width: 123.29px;
        height: 169.8px;
        background: url("../../assets/imgs/yingxiao/9.png");
        background-size: 100% 100%;
        position: absolute;
        bottom: 278px;
        right: 207px;
        opacity: 0;
        transition: all 0.4s linear;
      }

      .sc {
        width: 137.35px;
        height: 117.89px;
        background: url("../../assets/imgs/yingxiao/sc.png");
        background-size: 100% 100%;
        position: absolute;
        bottom: 320px;
        left: 285px;
        z-index: 20;
        // opacity: 0;
        transition: all 0.4s linear;
      }

      .hb {
        width: 95.17px;
        height: 60.57px;
        background: url("../../assets/imgs/yingxiao/hb.png");
        background-size: 100% 100%;
        position: absolute;
        bottom: 361px;
        left: 422px;
        z-index: 20;
        //opacity: 0;
        transition: all 0.4s linear;
      }

      .gd {
        width: 91.93px;
        height: 70.3px;
        background: url("../../assets/imgs/yingxiao/gd.png");
        background-size: 100% 100%;
        position: absolute;
        bottom: 219px;
        left: 445px;
        z-index: 20;
        // opacity: 0;
        transition: all 0.4s linear;
      }

      .sh {
        width: 15.14px;
        height: 17.3px;
        background: url("../../assets/imgs/yingxiao/sh.png");
        background-size: 100% 100%;
        position: absolute;
        bottom: 362px;
        left: 585px;
        z-index: 20;
        //  opacity: 0;
        transition: all 0.4s linear;
      }

      .bj {
        width: 25.96px;
        height: 25.96px;
        background: url("../../assets/imgs/yingxiao/bj.png");
        background-size: 100% 100%;
        position: absolute;
        bottom: 517px;
        left: 507px;
        z-index: 20;
        // opacity: 0;
        transition: all 0.4s linear;
      }

      .dian {
        width: 38px;
        height: 22px;
        background: rgba(145, 194, 255, 1);
        position: absolute;
        border-radius: 55px;
        z-index: 50;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        line-height: 16px;
        color: #ffffff;

        .dingwei {
          width: 18px;
          height: 23.7px;
          position: absolute;
          left: 10px;
          top: 25px;
        }
      }

      .dian-active {
        background-color: rgba(77, 143, 255, 1);
      }

      .op1 {
        opacity: 1 !important;
      }
    }

    .map {
      width: 704px;
      height: 688px;
      background: url("../../assets/imgs/guanyu/map.png");
      background-size: 100% 100%;
      position: relative;

      .son {
        width: 38px;
        height: 22px;
        background: rgba(145, 194, 255, 1);
        opacity: 1;
        border-radius: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        line-height: 16px;
        color: #ffffff;
        position: absolute;

        img {
          width: 18px;
          height: 24px;
          position: absolute;
          bottom: -26px;
          left: 11px;
        }
      }
    }

    .right {
      width: 622px;

      .name {
        font-size: 16px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        line-height: 21px;
        color: #333333;
        opacity: 1;
      }

      .nav {
        margin-top: 50px;
        display: flex;
        height: 50px;
        width: 100%;
        font-size: 18px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        line-height: 24px;
        color: #a2a2a2;
        border-bottom: 2px solid rgba(235, 235, 235, 1);
        opacity: 1;

        .son {
          width: 80px;
          position: relative;
          cursor: pointer;

          em {
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 40px;
            height: 2px;
            background-color: rgba(68, 112, 255, 1);
          }
        }
      }

      .mian {
        margin-top: 40px;

        .son {
          font-size: 14px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;

          color: #888888;
          opacity: 1;
          margin-top: 12px;
        }
      }
    }
  }
}

.bottom-num {
  width: 100%;
  height: 180px;
  background: rgba(27, 27, 27, 1);
  padding: 36px 300px;

  display: flex;
  justify-content: space-between;

  .son {
    //width: 25%;
    height: 100%;

    .name {
      font-size: 16px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: 400;
      color: #888888;
    }

    .num {
      font-size: 60px;
      font-family: Alibaba PuHuiTi;
      font-weight: bold;
      margin-top: 16px;
      color: #ffffff;
      position: absolute;

      .danwei {
        font-size: 20px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        top: 0px;
        right: -30px;
      }
    }
  }
}
</style>
